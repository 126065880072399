/**
 * Application entry point.
 *
 * This file includes `import`'s for all the components/directives used
 * by the HTML page and the controller to provide the configuration.
 */
import './sass/desktop.scss';
import './sass/vars_desktop.scss';
import './sass/desktop.scss';

import angular from 'angular';
import {isEventUsingCtrlKey} from 'ngeo/utils';
import gmfControllersAbstractDesktopController, {
  AbstractDesktopController,
} from 'gmf/controllers/AbstractDesktopController';
import geocommunesBase, {init} from '../geocommunesmodule';
import ngeoMiscToolActivate from 'ngeo/misc/ToolActivate';
import ngeoStreetviewModule from 'ngeo/streetview/module';
import panels from 'gmfapi/store/panels';

/**
 * @param {angular.Scope} $scope Scope.
 * @param {angular.$injector} $injector Main injector.
 * @constructor
 * @extends {gmf.controllers.AbstractDesktopController}
 * @ngInject
 * @export
 * @private
 */
class Controller extends AbstractDesktopController {
  /**
   * @param {angular.IScope} $scope Scope.
   * @param {angular.auto.IInjectorService} $injector Main injector.
   * @param {string} geocommuneCss
   * @ngInject
   */
  constructor($scope, $injector, geocommuneOptions) {
    super($scope, $injector);
    init($injector, geocommuneOptions);

    /**
     * @type {boolean}
     */
    this.drawLidarprofilePanelActive = false;

    const drawLidarprofilePanelActive = new ngeoMiscToolActivate(this, 'drawLidarprofilePanelActive');
    this.ngeoToolActivateMgr.registerTool('mapTools', drawLidarprofilePanelActive, false);

    const $timeout = $injector.get('$timeout');

    // Open the 'web-component' lidar panel
    $scope.$watch(
      () => this.drawLidarprofilePanelActive,
      (newVal) => {
        if (newVal) {
          panels.openToolPanel('lidar');
        } else {
          panels.closeToolPanel();
        }
      }
    );

    // Make visible the footer
    panels.getActiveFooterPanel().subscribe({
      next: (panel) => {
        this.lidarProfileFooterActive = panel === 'lidar';
        $timeout(() => {}); // this triggered on DOM click, we call $timeout to force Angular digest
      },
    });
  }

  /**
   * @param {JQuery.Event} event keydown event.
   */
  onKeydown(event) {
    if (event && isEventUsingCtrlKey(event) && event.key === 'p') {
      this.printPanelActive = true;
      event.preventDefault();
    }
  }
}

const geocommunesModule = angular.module('Appdesktop', [
  geocommunesBase.name,
  gmfControllersAbstractDesktopController.name,
  ngeoStreetviewModule.name,
]);

geocommunesModule.value('gmfContextualdatacontentTemplateUrl', 'gmf/contextualdata');
geocommunesModule.run(
  /**
   * @ngInject
   * @param {angular.ITemplateCacheService} $templateCache
   */
  ($templateCache) => {
    // @ts-ignore: webpack
    $templateCache.put('gmf/contextualdata', require('./contextualdata.html'));
  }
);

geocommunesModule.controller('DesktopController', Controller);

export default geocommunesModule;
