/**
 * This file provides the "geocommunes" namespace, which is the
 * application's main namespace. And it defines the application's Angular
 * module.
 */
import {decodeQueryString} from 'ngeo/utils.js';
import angular from 'angular';

/**
 * @param {angular.auto.IInjectorService} $injector Main injector.
 * @param {dict} geocommuneOptions
 */
export function init($injector, geocommuneOptions) {
  document.title = geocommuneOptions.title;

  if (geocommuneOptions.css) {
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = geocommuneOptions.css;
    head.appendChild(link);
  }
  if ($injector.has('geocommuneFavicon')) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = $injector.get('geocommuneFavicon');
  }
}

/**
 * @type {!angular.IModule}
 */
const myModule = angular.module('geocommunes', []);

myModule.config([
  '$compileProvider',
  function ($compileProvider) {
    if (!('debug' in decodeQueryString(window.location.search))) {
      // Disable the debug info
      $compileProvider.debugInfoEnabled(false);
    }
  },
]);

export default myModule;
